<template>
  <div class=" container mx-auto py-16">
    <div class="flex flex-col justify-center items-center text-center">
      <h1 class=" justify-center text-blue-800 font-bold text-2xl">Your account is created ! 🎉</h1>
      <span class="text-blue-800 font-semibold text-sm mb-8">Check out this video</span>
        
      <iframe src="https://player.vimeo.com/video/495534937?title=0&byline=0&portrait=0" class="w-10/12 lg:w-9/12 sm:h-64 md:h-72 lg:h-128 mb-8" frameborder="0"></iframe>

      <!-- <span class=" text-grayHome text-sm font-medium px-20">Your feedback is valuable to us.</span>
      <span class=" font-light text-sm text-grayHome mb-16 px-20">Click the purple icon if you have any questions, feeback, etc 👉</span> -->

      <router-link :to="{name:'Dashboard'}" class=" rounded-full px-20 py-5 bg-gray-50 font-semibold text-black disabled:opacity-50">
        <span>Go to your dashboard</span>
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name:'ThankYou'
  }
</script>
